import React from 'react'
import CityPage from '../../components/cityPage'
import image from '../../images/cities/scale-model-of-visalia-california.png'
import image0 from "../../images/cities/scale-model-of-visit-visalia-in-visalia-california.png"
import image1 from "../../images/cities/scale-model-of-visalia-convention-&-visitors-in-visalia-california.png"

export default class LandingPage extends React.Component<{}, {}> {
  render() {
    return (
        <CityPage
            city='Visalia'
            state='California'
            image={image}
            lat='36.3302284'
            lon='-119.2920585'
            attractions={ [{"name": "Visit Visalia", "vicinity": "112 E Main St, Visalia", "types": ["travel_agency", "point_of_interest", "establishment"], "lat": 36.3304319, "lng": -119.29188479999999}, {"name": "Visalia Convention & Visitors", "vicinity": "303 E Acequia Ave, Visalia", "types": ["travel_agency", "point_of_interest", "establishment"], "lat": 36.328904, "lng": -119.2906706}] }
            attractionImages={ {"Visit Visalia":image0,"Visalia Convention & Visitors":image1,} }
       />);
  }
}